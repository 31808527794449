/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/indent */
// @ts-ignore
// eslint-disable-next-line import/extensions
import { defaults } from './defaultConfigs';

export const Localization = {
  ...defaults,
  assignedCs: 'Field Collector',
  csId: 'Employee ID',
  currencySymbol: '₱',
  currencyAbbrev: 'PHP',
  branch: 'Locations',
  accountBranch: 'Branch',
  branches: 'Branches',
  collectionSpecialist: 'Field Collectors',
  principalPayment: 'Outstanding Payment*',
  showPenaltyPayment: true,
  penaltyPayment: 'Charges Received',
  csLeaderBoard: 'CS Leaderboard',
  branchLeaderBoard: 'Branch Leaderboard',
  collectionsBarChartTitle: 'Field Collections',
  accountsBarChartTitle: 'Field Accounts',
  collectionSpecialistPerformanceTitle: 'Field Collectors Performance',
  branchListingHeader: 'Locations',
  branchListingBreadcrumb: 'Locations',
  csListingHeader: 'Field Collectors',
  csListingBreadcrumb: 'Field Collectors',
  csDetailsBreadcrumb: 'Specialist',
  logoFilename: 'Logo_motorace.png',
  clientName: 'TVSC',
  navbarBackgroundColor: '#5665D2',
  showManuals: true,
  showAIManuals: false,
  showAPK: false,
  csManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/TVSC_CS_UserManual.pdf',
  cashierManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Generic_Cashier_UserManual.pdf',
  managerManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/TVSC_Manager_UserManual.pdf',
  aiManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/TVSC_AI_Itinerary.pdf',
  apkLink: process.env.APK_URL || '',
  eulaTextUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_TVSC_mobile.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/eula/EULA_TVSC_mobile.json',
  privacyStatementUrl:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
  privacyStatementVersion:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
  zipcodeLength: 6,
  zipcodeValidationMsg: 'India zipcode should have 6 characters. Ex: 744202, 683544',
  country: 'India',
  countryAbbrev: 'IN',
  transactionListingHeader: 'Transaction Listing',
  transactionListingBreadcrumb: 'Transaction Listing',
  activateTransactionListing: true,
  activateCreditDebitMemo: true,
  activatePPD: false,
  currencyLocale: 'en-PH',
  hiddenPenaltyField: false,
  csReportDownload: false,
  cashierReportDownload: true,
  appId: '1:834347563222:web:a0b0e7503490cdd69ab177',
  measurementId: 'G-ZZX8XK1RYR',
  apiKey: 'AIzaSyCjJ4qq0cRwMo5RLPDTbgGPVY1b8IfnvBI',
  showTranslation: false,
  recoveredAccountsLabel: 'ACCOUNT VISITED',
  noOfPaymentsLabel: 'NO. OF RECORDS',
  showCashierPage: true,
  displayClientCode: false,
  metricsToDisplay: ['mtd-collection-efficiency', 'account-recovery-rate', 'average-visits-per-day'],
  cspStatsToHide: ['total_skipped_visits', 'itinerary_non_compliance', 'visit_per_day'],
  showSummaryReportDownload: true,
  collectionSpecialistItinerary: 'Field Collector Itinerary',
  ipAddressUrlOne: 'https://api.ipify.org/?format=json',
  ipAddressUrlTwo: 'https://api.bigdatacloud.net/data/client-ip',
  employeeID: true,
  dateTime: { monthIndex: -1, startDate: 21, endDate: 20 },
  zendeskToken: 'decf2f78-5072-4248-8294-d2aec9deb324',
  repossessionConfig: {
    managerInitRepo: false,
    repoInitiationApproval: false,
    repo_repossess: 'Repo',
    repo_seizure: 'Seizure',
    repo_inward: 'Inward',
    repo_inward_approved: 'Inward Approved',
    repo_inward_rejected: 'Inward Rejected',
    repo_released: 'Released',
    repossessionLabel: 'Repossession',
    seizureLabel: 'Seizure',
    inwardLabel: 'Inward',
  },
  branchReassign: true,
  showRecordsTab: false,
  showScrollsTab: true,
  vehicleRegistrationNumber: 'Vehicle Registration Number',
  showBusinessAddress: false,
  showOfficeAddress: false,
  showViberID: false,
  showBucket: true,
  showVehicleType: true,
  showVehicleColor: true,
  showRegistrationNumber: true,
  showVehicleBrand: false,
  showVehicleSeries: false,
  showPlateNumber: false,
  showVehicleOwnerName: false,
  showVehicleLastDateRenewed: false,
  showVehicleValidUntil: false,
  showPaidOffus: true,
  showOffusMaxBucket: true,
  showAccountDocumentImages: false,
  showVehicleRCFlag: true,
  assetSections: {
    vehicle: true,
    rem: false,
    goldAndGems: false,
  },
  label: {
    address: 'Address',
    email: 'Email',
    monthsOnBook: 'Months On Book',
    phoneNumber: 'Phone Number',

    agreementStatus: 'Agreement Status',
    loanTenure: 'Loan Tenure',
    portfolio: 'Portfolio',
    principalOutstanding: 'Principal Outstanding',
    totalOutstanding: 'Total Outstanding',
    maxDaysPastDue: 'Max Days Past Due',

    vehicleModel: 'Vehicle Model',
    releaseToCustomerLabel: 'Release To Customer',
  },
  collectorVisitBreakdown: false,
  disableVehicleCollectedBy: false,

  showCollectionMetric: true,
  showProductivityMetric: true,
  showAllocationMetric: false,
  showComplianceMetric: true,
  showFunnelMetric: true,
  showAnalyticsPortfolioFilter: false,
  showAnalyticsAllocationChannel: false,
  showAnalyticsBucketFilter: false,
  showAnalyticsCustomerVintage: false,
  showAnalyticsPaymentFrequency: false,
  showAnalyticsCycleFilter: false,
};
